<template>
  <Box
    flex
    justify="start"
    alignItems="center"
    class="gap-1"
  >
    <Typography :variant="variant" :color="color" :uppercase="uppercase">
      <slot />
    </Typography>
    <Typography v-if="required" color="error" :variant="variant" component="span">
      *
    </Typography>
  </Box>
</template>

<script lang="ts" setup>
import Box from '../Box/Box.vue'
import Typography from '../Typography/Typography.vue'
import type { TypographyColor, TypographyVariant } from '../Typography/types'

const {
  required = false,
  variant,
  color,
  uppercase = false,
} = defineProps<{
  required?: boolean
  variant?: TypographyVariant
  color?: TypographyColor
  uppercase?: boolean
}>()
</script>
