<template>
  <Box flex :direction="direction" spaceX="4" spaceY="1.5" :alignItems="direction === 'row' ? 'center' : 'start'" py="2">
    <slot />
  </Box>
</template>

<script lang="ts" setup>
import Box from '../Box/Box.vue'
import { BoxFlexDirection } from '../Box'

const { direction = 'row' } = defineProps<{
  direction?: BoxFlexDirection
}>()
</script>
