<template>
  <Box class="layout-base">
    <slot />

    <slot v-if="!noFooter" name="footer">
      <IqviaFooter />
    </slot>
  </Box>
</template>

<script setup lang="ts">
import { IqviaFooter } from '../../IqviaFooter'
import { Box } from '../../Box'

const { noFooter = false } = defineProps<{
  noFooter?: boolean
}>()
</script>

<style scoped>
.layout-base {
  @apply flex flex-col gap-4;
  min-height: calc(100vh - 96px);
}
</style>
