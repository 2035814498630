<template>
  <Box :data-test-for="id">
    <Box
      v-if="title || $slots.title"
      :pb="$slots.default ? '4' : undefined"
      flex alignItems="center"
      justify="between"
      spaceX="2"
      spaceY="2"
      wrap="wrap"
    >
      <slot name="title">
        <Box flex alignItems="start" spaceX="1">
          <FormFieldLabel data-test-id="form-section-title" variant="h6" color="textPrimary" :required="required">
            {{ title }}
          </FormFieldLabel>

          <Tooltip
            v-if="$slots.tooltip || tooltip"
            placement="top"
            offsetDistance="8"
            class="flex"
          >
            <template #default>
              <Icon
                icon="info"
                size="xs"
                variant="outlined"
                color="textSecondaryLight"
                class="cursor-pointer"
              />
            </template>

            <template #content>
              <slot name="tooltip">
                {{ tooltip }}
              </slot>
            </template>
          </Tooltip>
        </Box>
        <Box v-if="$slots.actions" flex spaceX="2" spaceY="2" wrap="wrap" alignItems="center">
          <slot name="actions" />
        </Box>
      </slot>
    </Box>

    <slot :id="id" />

    <Transitions>
      <FormError :id="id" pt="1" :errorText="errorText" />
    </Transitions>
  </Box>
</template>

<script setup lang="ts">
import Box from '../Box/Box.vue'
import FormError from '../FormError/FormError.vue'
import Transitions from '../Transitions/Transitions.vue'
import Tooltip from '../Tooltip/Tooltip.vue'
import Icon from '../Icon/Icon.vue'
import FormFieldLabel from '../FormFieldLabel/FormFieldLabel.vue'

withDefaults(defineProps<{
  id?: string
  title?: string
  required?: boolean
  errorText?: string
  tooltip?: string
}>(), {
  id: '',
  errorText: '',
  tooltip: '',
})
</script>
