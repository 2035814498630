<template>
  <LayoutBase :noFooter="noFooter">
    <Box flex>
      <Box flex col space="8" grow="grow" shrink="shrink" minWidth="0">
        <Box flex col space="4" :class="{ 'sticky-header': stickyHeader }">
          <Box flex space="4">
            <Box flex col space="4" grow="grow" minWidth="0">
              <Box flex alignItems="center" space="4" minHeight="36px">
                <PageTitle>
                  <template v-if="$slots.title" #title>
                    <slot name="title" />
                  </template>
                  <template v-if="$slots.titlePlaceholder" #titlePlaceholder>
                    <slot name="titlePlaceholder" />
                  </template>
                </PageTitle>

                <slot name="info" />

                <Transitions mode="out-in">
                  <Box :key="actionsKey ?? undefined" flex space="2" justify="end" grow="grow" shrink="shrink-0">
                    <slot name="actions" />
                  </Box>
                </Transitions>

                <Box v-if="$slots.summary" shrink="shrink-0" class="summary-wrapper">
                  <Box class="summary">
                    <slot name="summary" />
                  </Box>
                </Box>
              </Box>
              <Box v-if="$slots.breadcrumbs">
                <slot name="breadcrumbs" />
              </Box>
            </Box>
            <Box v-if="$slots.headerAside">
              <slot name="headerAside" />
            </Box>
          </Box>

          <Box v-if="$slots.tabs" pt="1">
            <slot name="tabs" />
          </Box>
        </Box>

        <slot />
      </Box>

      <Transitions name="slide-x">
        <slot name="aside" />
      </Transitions>
    </Box>

    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </LayoutBase>
</template>

<script setup lang="ts">
import { Box } from '../../Box'
import { PageTitle } from '../../PageTitle'
import { LayoutBase } from '../LayoutBase'
import { Transitions } from '../../Transitions'

const {
  stickyHeader = false,
  noFooter = false,
  actionsKey,
  summaryWidth = '365px',
} = defineProps<{
  stickyHeader?: boolean
  noFooter?: boolean
  actionsKey?: PropertyKey | null
  summaryWidth?: string
}>()
</script>

<style scoped>
.sticky-header {
  @apply sticky z-20 top-0 bg-base-200 -mx-3 px-3 pb-6 -mb-6;
}

.summary-wrapper {
  @apply relative;
  width: v-bind(summaryWidth);
}

.summary {
  @apply absolute -top-4 right-0 z-10;
  width: v-bind(summaryWidth);
}
</style>
