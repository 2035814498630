<template>
  <Box justify="center" alignItems="center" flex direction="col" spaceY="4" p="10">
    <Paper
      color="primary-light"
      rounded="xl"
      flex
      justify="center"
      alignItems="center"
      p="3"
    >
      <Icon :icon="icon" :variant="iconVariant" color="primary" :size="iconSize" />
    </Paper>
    <Typography variant="body2" color="textPrimary">
      <slot name="message">
        {{ message }}
      </slot>
    </Typography>
  </Box>
</template>

<script lang="ts" setup>
import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import Paper from '../Paper/Paper.vue'
import Typography from '../Typography/Typography.vue'

import { IconPropTypes } from '../Icon'

withDefaults(defineProps<{
  icon?: IconPropTypes['icon']
  iconVariant?: IconPropTypes['variant']
  iconSize?: IconPropTypes['size']
  message?: string
}>(), {
  icon: 'info',
  iconVariant: 'outlined',
  iconSize: 'lg',
  message: 'No data available',
})
</script>
